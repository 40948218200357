// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-einfach-kostbar-js": () => import("./../../../src/pages/einfach-kostbar.js" /* webpackChunkName: "component---src-pages-einfach-kostbar-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-optin-js": () => import("./../../../src/pages/optin.js" /* webpackChunkName: "component---src-pages-optin-js" */),
  "component---src-pages-smoothies-snacks-js": () => import("./../../../src/pages/smoothies-snacks.js" /* webpackChunkName: "component---src-pages-smoothies-snacks-js" */),
  "component---src-pages-standorte-js": () => import("./../../../src/pages/standorte.js" /* webpackChunkName: "component---src-pages-standorte-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

